<template>
  <el-scrollbar id="app" :class="getLanguageClass">
    <Header8v @onApiClick="onApiClick" ref="header8v"></Header8v>

    <div class="app-main">
      <router-view v-if="$route.meta.title == '首页'"></router-view>
      <router-view
        v-else
        v-wechat-title="
          translateTitle($route.meta.title) + ' | ' + translateTitle(title)
        "
      ></router-view>
    </div>
    <div class="top-icon" @click="onTopClick" v-show="gotop">
      <img src="./assets/img/mask/top-icon.png" alt="" />
    </div>
    <ChatWedget
      v-if="!isMaskex && !is8V && !is8Coin && !isHx"
      ref="ChatWedget"
    />
    <FooterMaskex v-if="!hideFooter && isMaskex"></FooterMaskex>
    <Footer8v
      v-if="!hideFooter && is8V"
      @tipsDownload="tipsDownload"
      @chatOpen="onApiClick"
    ></Footer8v>
    <Footer
      v-if="!hideFooter && !isMaskex && !is8V"
      @onApiClick="onApiClick"
    ></Footer>
    <v8KYCDialog v-if="v8show" />

    <el-dialog
      :title="translateTitle('提示')"
      :visible.sync="showWarnDialog"
      :close-on-click-modal="false"
      custom-class="v8-warn"
      width="500px"
      center
    >
      <span>{{ warnInfo }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showWarnDialog = false">{{
          translateTitle("我已经知晓")
        }}</el-button>
      </span>
    </el-dialog>
  </el-scrollbar>
</template>
<script>
import Header8v from "@/components/common/header-8v.vue";
import v8KYCDialog from "@/components/common/8v-KYC-dialog.vue";
import Footer from "@/components/common/footer.vue";
import Footer8v from "@/components/common/footer-bv.vue";
import FooterMaskex from "@/components/common/footer-maskex.vue";
import { translateTitle } from "@/assets/js/i18n";
import ChatWedget from "./pages/customer-service/ChatWedget.vue";
import { mapActions, mapGetters } from "vuex";
import { VUE_CHANNEL_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { getLangPackage } from "@/assets/js/lang";
import seoMixin from "@/pages/seo/seoMixin.vue";
import { apiCheckIpConfig } from "@/model/user/security";
// import seoMixinHx from "@/pages/seo/seoMixinHx.vue";
export default {
  name: "App",
  mixins: [seoMixin],
  components: {
    Header8v,
    Footer,
    Footer8v,
    FooterMaskex,
    ChatWedget,
    v8KYCDialog,
  },
  data() {
    return {
      title: "数字货币银行",
      groupId: "20",
      hiddenCustomer: "",
      hideFooter: false,
      gotop: false,
      isMaskex: false,
      is8V: true,
      isS6: false,
      isB9: false,
      isUEEx: false,
      is5A: false,
      isBitda: false,
      is8Coin: false,
      isHx: false,
      isRouterAlive: true,
      warnInfo: "",
      showWarnDialog: false,
      hideCustomer: true,
      sessionVariables: {
        uid: "",
        email: "",
        google: "",
        mobile: "",
        page: "",
        kyc_level: "",
      },
      countryList: [
        {
          id: "English",
          value: "en",
          valueFull: "en_US",
          id: "22",
        },
        {
          name: "日本語",
          value: "ja",
          valueFull: "ja_JP",
          id: "24",
        },
        {
          name: "한국인",
          value: "ko",
          valueFull: "ko_KR",
          id: "23",
        },

        {
          name: "Français",
          value: "fr",
          valueFull: "fr_FR",
          id: "26",
        },
        {
          name: "Español",
          value: "es",
          valueFull: "es_ES",
          id: "27",
        },
        {
          name: "Pусский",
          value: "ru",
          valueFull: "ru_RU",
          id: "28",
        },
        {
          name: "Deutsch",
          value: "de",
          valueFull: "de_DE",
          id: "29",
        },
        {
          name: "Português",
          value: "pt",
          valueFull: "pt_BR",
          id: "30",
        },
        {
          name: "हिंदी",
          value: "hi",
          valueFull: "hi_IN",
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          valueFull: "vi_VN",
          id: "32",
        },
        {
          name: "Pilipino",
          value: "tl",
          valueFull: "tl_PH",
          id: "33",
        },
        {
          name: "ภาษาไทย",
          value: "th",
          valueFull: "th_TH",
          id: "34",
        },
        {
          name: "Türk",
          value: "tr",
          valueFull: "tr_TR",
          id: "35",
        },
        {
          name: "Italiano",
          value: "it",
          valueFull: "it_IT",
          id: "36",
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          valueFull: "id_ID",
        },
        {
          name: "عربي",
          value: "ar",
          valueFull: "ar_AR",
          id: "25",
        },
        {
          name: "中文简体",
          value: "zh_CN",
          valueFull: "zh_CN",
          id: "20",
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          valueFull: "zh_HK",
          id: "21",
        },
      ],
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
        this.sessionVariables.email = this.userInfo.authInfo.open_email
          ? "yes"
          : "no";
        this.sessionVariables.google =
          this.userInfo.authInfo.open_google == 1 ? "yes" : "no";
        this.sessionVariables.mobile =
          this.userInfo.authInfo.open_mobile == 1 ? "yes" : "no";
        let DOMAIL = document.location.pathname;
        if (DOMAIL.indexOf("/login") >= 0) {
          this.sessionVariables.page = "log";
        } else if (DOMAIL.indexOf("/register") >= 0) {
          this.sessionVariables.page = "reg";
        } else if (DOMAIL.indexOf("/quicklycoins") >= 0) {
          this.sessionVariables.page = "qui";
        } else if (DOMAIL.indexOf("/markets") >= 0) {
          this.sessionVariables.page = "mar";
        } else if (DOMAIL.indexOf("/contract-exchange") >= 0) {
          this.sessionVariables.page = "fut";
        } else if (DOMAIL.indexOf("/exchange") >= 0) {
          this.sessionVariables.page = "spo";
        } else if (DOMAIL.indexOf("/delivery") >= 0) {
          this.sessionVariables.page = "fcm";
        } else if (DOMAIL.indexOf("/finances") >= 0) {
          this.sessionVariables.page = "fim";
        } else if (DOMAIL.indexOf("/aboutUs") >= 0) {
          this.sessionVariables.page = "abo";
        } else if (DOMAIL.indexOf("/ucenter") >= 0) {
          this.sessionVariables.page = "uct";
        }
        this.sessionVariables.kyc_level =
          this.userInfo &&
          this.userInfo.kycInfo &&
          this.userInfo.kycInfo.kyc_level;
      }
    },
  },
  created() {
    this.setTheme();
    const DOMAIL = document.location.pathname;
    if (this.userInfo) {
      this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
      this.sessionVariables.email = this.userInfo.authInfo.open_email
        ? "yes"
        : "no";
      this.sessionVariables.google =
        this.userInfo.authInfo.open_google == 1 ? "yes" : "no";
      this.sessionVariables.mobile =
        this.userInfo.authInfo.open_mobile == 1 ? "yes" : "no";
    }

    if (DOMAIL.indexOf("/login") >= 0) {
      this.sessionVariables.page = "log";
    } else if (DOMAIL.indexOf("/register") >= 0) {
      this.sessionVariables.page = "reg";
    } else if (DOMAIL.indexOf("/quicklycoins") >= 0) {
      this.sessionVariables.page = "qui";
    } else if (DOMAIL.indexOf("/markets") >= 0) {
      this.sessionVariables.page = "mar";
    } else if (DOMAIL.indexOf("/contract-exchange") >= 0) {
      this.sessionVariables.page = "fut";
    } else if (DOMAIL.indexOf("/exchange") >= 0) {
      this.sessionVariables.page = "spo";
    } else if (DOMAIL.indexOf("/delivery") >= 0) {
      this.sessionVariables.page = "fcm";
    } else if (DOMAIL.indexOf("/finances") >= 0) {
      this.sessionVariables.page = "fim";
    } else if (DOMAIL.indexOf("/aboutUs") >= 0) {
      this.sessionVariables.page = "abo";
    } else if (DOMAIL.indexOf("/ucenter") >= 0) {
      this.sessionVariables.page = "uct";
    }
    this.sessionVariables.kyc_level =
      this.userInfo && this.userInfo.kycInfo && this.userInfo.kycInfo.kyc_level;
    this.$router.afterEach(() => {
      this.isHasFooter();
    });
    this.$router.beforeEach((to, from, next) => {
      this.isHasFooter();
      next();
    });
    this.isHasFooter();

    setInterval(() => {
      window.location.reload();
    }, 6 * 60 * 60 * 1000);

    this.countryList.forEach(ele => {
      if (ele.value == this.language) {
        this.groupId = ele.id;
      }
    });
    this.chooseLanguage();
    this.checkIpConfig();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      v8show: "user/v8show",
      language: "user/language",
      darkMode: "user/darkMode",
    }),

    getLanguageClass() {
      let tempClass = "";
      tempClass = this.language;
      if (tempClass == "" || tempClass == null || tempClass == undefined) {
        if (this.is8V || this.isMaskex || this.is5A || this.isHx) {
          tempClass = "en";
        } else if (this.isS6 || this.isB9 || this.isBitda) {
          let jsSrc = (
            navigator.language || navigator.browserLanguage
          ).toLowerCase();
          if (jsSrc == "zh-hk" || jsSrc == "zh-tw") {
            tempClass = "zh_HK";
          } else if (jsSrc.indexOf("zh") >= 0) {
            // 假如浏览器语言是中文
            tempClass = "zh_CN";
          } else {
            // 假如浏览器语言是英文
            tempClass = "en";
          }
        } else {
          tempClass = "zh_CN";
        }
      }
      // window.location.search = `/lang=${tempClass}`;
      return tempClass;
    },
  },

  methods: {
    translateTitle,
    checkIpConfig() {
      apiCheckIpConfig().then(res => {
        if (res.status == -900200001) {
          // if (res.status == 200) {
          this.warnInfo = res.msg;
          this.showWarnDialog = true;
        }
      });
    },
    handleNewEvent(event) {
      // console.log("LiveChatWidget.onNewEvent", event);
    },
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    tipsDownload() {
      this.$refs.header8v.onDownloadTipsDialog();
    },
    onApiClick() {
      this.$refs.ChatWedget.onChatClick();
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    onTopClick() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    setTheme() {
      this.is8V = true;
      this.title = "8V.com - 全球首家元宇宙生态数字资产交易所";
      document.getElementsByTagName("body")[0].className = "vab-theme-8V";
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    showMaskexCustomerService() {
      const index1 = location.pathname.indexOf("/landingpage/register");
      if (index1 == -1) {
        this.showMaskexCustomer = true;
      } else {
        this.showMaskexCustomer = false;
      }
    },
    isHasFooter() {
      const index = location.pathname.indexOf("/login");
      const index1 = location.pathname.indexOf("/register");
      const index2 = location.pathname.indexOf("/assets");
      const index3 = location.pathname.indexOf("/forgetPwd");
      const index4 = location.pathname.indexOf("/LoginSecurity");
      const index5 = location.pathname.indexOf("/landingpage/register");
      if (
        index != -1 ||
        index1 != -1 ||
        index3 != -1 ||
        index4 != -1 ||
        index5 != -1
      ) {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
      if (index5 != -1) {
        this.hideCustomer = true;
        this.hiddenCustomer = "hidden";
      } else {
        this.hiddenCustomer = "";
        this.hideCustomer = false;
      }
    },

    chooseLanguage() {
      let lastIsLang = false;
      let lastpath = window.location.href.split("/");
      this.countryList.forEach(item => {
        if (item.value == lastpath[lastpath.length - 1]) {
          lastIsLang = true;
        }
      });
      if (
        (VUE_UNION_STATION == "8V" || VUE_UNION_STATION == "TopCredit") &&
        !lastIsLang
      ) {
        const langPackage = getLangPackage(["en"]);
        this.$i18n.setLocaleMessage("en", langPackage["en"]);
        this.changeLanguage("en");
        this.$i18n.locale = "en";
        return;
      }
      // if (
      //   VUE_UNION_STATION == "8V" &&
      //   (window.location.href.endsWith("en") ||
      //     window.location.href.endsWith("en/"))
      // ) {
      //   this.changeLanguage("");
      //   window.location.href = window.location.href
      //     .replace("en/", "")
      //     .replace("en", "");
      //   return;
      // }
      if (
        (VUE_UNION_STATION == "8V" || VUE_UNION_STATION == "TopCredit") &&
        lastIsLang
      ) {
        const langPackage = getLangPackage([lastpath[lastpath.length - 1]]);
        this.$i18n.setLocaleMessage(
          lastpath[lastpath.length - 1],
          langPackage[lastpath[lastpath.length - 1]]
        );
        this.changeLanguage(lastpath[lastpath.length - 1]);
        this.$i18n.locale = lastpath[lastpath.length - 1];
        return;
      }
      if (!(this.$route.params && this.$route.params.lang)) return;
      const langPackage = getLangPackage([this.$route.params.lang]);
      this.$i18n.setLocaleMessage(
        this.$route.params.lang,
        langPackage[this.$route.params.lang]
      );
      this.changeLanguage(this.$route.params.lang);
      this.$i18n.locale = this.$route.params.lang;
    },
  },
};
</script>

<style lang="scss">
#app {
  &.fr {
    .app-main .side-menu {
      width: 266px;
    }
  }
  &.es {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ru {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.de {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.vi {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.it {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.pt {
    .app-main .side-menu {
      width: 270px;
    }
  }
  &.tl {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ar {
    .app-main {
      .home-page {
        // direction: rtl;
      }
    }
  }
}
#app {
  display: flex;
  font-family: PingFang SC, robotoregular, Hiragino Sans GB, Heiti SC,
    Microsoft YaHei, WenQuanYi Micro Hei, Helvetica, Arial, monospace, serif !important;
  flex-direction: column;
  font-style: normal;
  // min-width: 1440px;
  height: 100%;
  min-width: 1180px;
  background: #ecedf1;
  &.ar {
    .el-scrollbar__wrap {
      margin-right: 0;
      margin-left: -15px;
    }
  }
  .el-scrollbar__wrap {
    height: 102%;
  }
  // .el-scrollbar__view {
  //   height: 100%;
  // }
  .v8-warn {
    .el-button--primary {
      background-color: #f7b239;
      border-color: #f7b239;
      color: #000;
    }
  }
  .app-main {
    flex: 1;
    min-height: calc(100% - 281px);
    .side-menu {
      padding: 0px 0;
      background: rgba(255, 255, 255, 1);
      // border-radius: 4px;
      overflow: hidden;
      width: 206px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      z-index: 999;
      height: auto;
      float: left;

      .nav {
        li {
          text-align: left;
          justify-content: left;
          padding-left: 30px;
          box-sizing: border-box;
          height: 40px; // jason要求改为40

          i {
            margin-right: 15px;
          }
        }
        li.active {
          color: #fff;
          background: linear-gradient(
            315deg,
            rgba(39, 198, 131, 1) 0%,
            rgba(39, 198, 131, 1) 100%
          );
        }
      }
    }
    .el-form-item {
      text-align: left !important;
    }
  }
  .top-icon {
    position: fixed;
    right: 40px;
    bottom: 14%;
    cursor: pointer;
    z-index: 99999;
  }
  .el-checkbox {
    &:hover {
      .el-checkbox__input {
        .el-checkbox__inner {
          border-color: #409eff;
        }
      }
    }
  }
}
</style>
