<template>
  <div class="page-footer-bv">
    <div class="split-line"></div>
    <div class="first-content">
      <div class="page-footer-content">
        <div>
          <ul class="logo-content">
            <li>
              <a href="/">
                <img :src="logImg" alt="8V logo medium silver" />
              </a>
            </li>
            <li class="slogan-c">
              {{ translateTitle("您的全球数字资产交易所") }}
            </li>
            <li class="contract">
              {{ translateTitle("技术支持") }}：support@8vex.com
            </li>
            <li class="contract">
              {{ translateTitle("商务洽谈") }}：business@8vex.com
            </li>
            <!-- <li class="sub-title">
              {{ slogan["web_slogan_" + language] }}
            </li> -->
          </ul>
        </div>

        <div class="tco" v-if="language == 'zh_CN'">
          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("关于") }}</li>
              <a href="https://8v.com/aboutus" target="_blank">
                <li>{{ translateTitle("关于8V") }}</li>
              </a>
              <a href="https://reurl.cc/7eNvpN" target="_blank">
                <li>{{ translateTitle("合规资讯") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-cn/categories/4413705023129"
                target="_blank"
              >
                <li>{{ translateTitle("公告中心") }}</li>
              </a>
              <a href="/service-agreement" target="_blank">
                <li>{{ translateTitle("用户协议") }}</li>
              </a>
              <a href="/privacy-policy" target="_blank">
                <li>{{ translateTitle("隐私协议") }}</li>
              </a>
              <a href="https://reurl.cc/qO2jYR" target="_blank">
                <li>{{ translateTitle("免责声明") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("产品") }}</li>
              <a href="https://reurl.cc/Rjgl9G" target="_blank">
                <li>{{ translateTitle("现货交易") }}</li>
              </a>
              <a
                href="https://8v.com/contract-exchange/btcusdt/zh_CN"
                target="_blank"
              >
                <li>{{ translateTitle("U本位合约") }}</li>
              </a>
              <a href="https://8v.com/delivery/ethusd/zh_CN" target="_blank">
                <li>{{ translateTitle("币本位合约") }}</li>
              </a>
              <a @click="tipsDownload" target="_blank">
                <li>{{ translateTitle("杠杆交易") }}</li>
              </a>
              <a href="https://8v.com/finances/zh_CN" target="_blank">
                <li>{{ translateTitle("理财商品") }}</li>
              </a>
              <a @click="tipsDownload" target="_blank">
                <li>{{ translateTitle("汇兑") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("服务") }}</li>
              <a href="/#downloadArea" target="_blank">
                <li>{{ translateTitle("APP下载") }}</li>
              </a>
              <a href="https://forms.gle/gmQLE96czTA5B9LK8" target="_blank">
                <li>{{ translateTitle("上币申请") }}</li>
              </a>

              <a
                href="https://info.8v.com/hc/zh-cn/articles/4409463868185-费率标准"
                target="_blank"
              >
                <li>{{ translateTitle("费率标准") }}</li>
              </a>
              <a href="mailto:business@8vex.com" target="_blank">
                <li>{{ translateTitle("商务联系") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("帮助") }}</li>
              <a
                href="https://info.8v.com/hc/zh-cn/categories/4413699059737-常见问题"
                target="_blank"
              >
                <li>{{ translateTitle("常见问题") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-cn/sections/4413976477977-账户指引"
                target="_blank"
              >
                <li>{{ translateTitle("账户指引") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-cn/sections/4413975188889-币种介绍"
                target="_blank"
              >
                <li>{{ translateTitle("币种介绍") }}</li>
              </a>
              <a @click="openChat" target="_blank">
                <li>{{ translateTitle("联系客服") }}</li>
              </a>
            </ul>
          </div>
        </div>

        <div class="tco" v-if="language == 'zh_HK'">
          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("关于") }}</li>
              <a href="https://8v.com/aboutus" target="_blank">
                <li>{{ translateTitle("关于8V") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-tw/articles/4429601336729-8V-com取得美國加拿大MSB雙牌照-為全球化佈局設立重要里程碑"
                target="_blank"
              >
                <li>{{ translateTitle("合规资讯") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-tw/categories/4413705023129-官方公告"
                target="_blank"
              >
                <li>{{ translateTitle("公告中心") }}</li>
              </a>
              <a href="/service-agreement" target="_blank">
                <li>{{ translateTitle("用户协议") }}</li>
              </a>
              <a href="/privacy-policy" target="_blank">
                <li>{{ translateTitle("隐私协议") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-tw/articles/4409472318617-免責聲明"
                target="_blank"
              >
                <li>{{ translateTitle("免责声明") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("产品") }}</li>
              <a href="https://8v.com/exchange/btc_usdt/zh_CN" target="_blank">
                <li>{{ translateTitle("现货交易") }}</li>
              </a>
              <a
                href="https://8v.com/contract-exchange/btcusdt/zh_CN"
                target="_blank"
              >
                <li>{{ translateTitle("U本位合约") }}</li>
              </a>
              <a href="https://8v.com/delivery/ethusd/zh_CN" target="_blank">
                <li>{{ translateTitle("币本位合约") }}</li>
              </a>
              <a @click="tipsDownload" target="_blank">
                <li>{{ translateTitle("杠杆交易") }}</li>
              </a>
              <a href="https://8v.com/finances/zh_HK" target="_blank">
                <li>{{ translateTitle("理财商品") }}</li>
              </a>
              <a @click="tipsDownload" target="_blank">
                <li>{{ translateTitle("汇兑") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("服务") }}</li>
              <a href="/#downloadArea" target="_blank">
                <li>{{ translateTitle("APP下载") }}</li>
              </a>
              <a href="https://forms.gle/gmQLE96czTA5B9LK8" target="_blank">
                <li>{{ translateTitle("上币申请") }}</li>
              </a>

              <a
                href="https://info.8v.com/hc/zh-tw/articles/4409463868185-費率標準"
                target="_blank"
              >
                <li>{{ translateTitle("费率标准") }}</li>
              </a>
              <a href="mailto:business@8vex.com" target="_blank">
                <li>{{ translateTitle("商务联系") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("帮助") }}</li>
              <a
                href="https://info.8v.com/hc/zh-tw/categories/4413699059737-常見問題"
                target="_blank"
              >
                <li>{{ translateTitle("常见问题") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-tw/sections/4413976477977-賬戶指引"
                target="_blank"
              >
                <li>{{ translateTitle("账户指引") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/zh-tw/sections/4413975188889-幣種介紹"
                target="_blank"
              >
                <li>{{ translateTitle("币种介绍") }}</li>
              </a>
              <a @click="openChat" target="_blank">
                <li>{{ translateTitle("联系客服") }}</li>
              </a>
            </ul>
          </div>
        </div>

        <div class="tco" v-if="language != 'zh_HK' && language != 'zh_CN'">
          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("关于") }}</li>
              <a href="https://8v.com/aboutus" target="_blank">
                <li>{{ translateTitle("关于8V") }}</li>
              </a>
              <a href="https://reurl.cc/dXgjWM" target="_blank">
                <li>{{ translateTitle("合规资讯") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/en-001/categories/4413705023129-Official-Announcements"
                target="_blank"
              >
                <li>{{ translateTitle("公告中心") }}</li>
              </a>
              <a href="/service-agreement" target="_blank">
                <li>{{ translateTitle("用户协议") }}</li>
              </a>
              <a href="/privacy-policy" target="_blank">
                <li>{{ translateTitle("隐私协议") }}</li>
              </a>
              <a href="	https://reurl.cc/Rjglk9" target="_blank">
                <li>{{ translateTitle("免责声明") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("产品") }}</li>
              <a href="https://8v.com/exchange/btc_usdt" target="_blank">
                <li>{{ translateTitle("现货交易") }}</li>
              </a>
              <a
                href="https://8v.com/contract-exchange/btcusdt"
                target="_blank"
              >
                <li>{{ translateTitle("U本位合约") }}</li>
              </a>
              <a href="https://8v.com/delivery/ethusd" target="_blank">
                <li>{{ translateTitle("币本位合约") }}</li>
              </a>
              <a @click="tipsDownload" target="_blank">
                <li>{{ translateTitle("杠杆交易") }}</li>
              </a>
              <a href="https://8v.com/finances" target="_blank">
                <li>{{ translateTitle("理财商品") }}</li>
              </a>
              <a @click="tipsDownload" target="_blank">
                <li>{{ translateTitle("汇兑") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("服务") }}</li>
              <a href="/#downloadArea" target="_blank">
                <li>{{ translateTitle("APP下载") }}</li>
              </a>
              <a href="https://forms.gle/gmQLE96czTA5B9LK8" target="_blank">
                <li>{{ translateTitle("上币申请") }}</li>
              </a>

              <a
                href="https://info.8v.com/hc/en-001/articles/4409463868185-Standard-Rate"
                target="_blank"
              >
                <li>{{ translateTitle("费率标准") }}</li>
              </a>
              <a href="mailto:business@8vex.com" target="_blank">
                <li>{{ translateTitle("商务联系") }}</li>
              </a>
            </ul>
          </div>

          <div>
            <ul class="text-content">
              <li class="sub-title">{{ translateTitle("帮助") }}</li>
              <a
                href="https://info.8v.com/hc/en-001/categories/4413699059737-FAQ"
                target="_blank"
              >
                <li>{{ translateTitle("常见问题") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/en-001/sections/4413976477977-Account-Functions"
                target="_blank"
              >
                <li>{{ translateTitle("账户指引") }}</li>
              </a>
              <a
                href="https://info.8v.com/hc/en-001/sections/4413975188889-Listing-Coins"
                target="_blank"
              >
                <li>{{ translateTitle("币种介绍") }}</li>
              </a>
              <a @click="openChat" target="_blank">
                <li>{{ translateTitle("联系客服") }}</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="split-h"></div>
    <div class="copy-right">
      <span>© 2023 8v.com. All rights reserved</span>
    </div>
    <!-- <div class="second-content V2">
      <p>
        BITDA LIMITED CANADA MSB number：M22902291
      </p>
      <p>
        ADDRESS：1530 ALBION ROAD TORONTO ON M9V 5H4
      </p>
      <p>
        BITDA LIMITED U.S MSB number：31000205754185
      </p>
      <p>ADDRESS：4255 South Buckley Road #1296 Aurora COLORADO 80013</p>
    </div> -->
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import logImg from "@/assets/img/bv/foot_logo.png";
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  data() {
    return {
      slogan: "",
      tgUrl: "",
      tipUrl: "",
      qrcodeTitle: "",
      rateUrl: "",
      serviceUrl: "",
      privacyUrl: "",
      statementUrl: "",
      helpUrl: "",
      projectName: "",
      logImg,
      apiUrl: "",
      // is8V: false,
      hasAPI: false,
    };
  },
  created() {
    // this.is8V = true;
    // this.logImg = require("../../assets/img/bv/logo_8V_new.png");
    // this.slogan = JSON.parse(sessionStorage.getItem("solgan"));
    // if (!JSON.parse(sessionStorage.getItem("clause"))) {
    //   getEnv();
    // }
    // let clauseList = JSON.parse(sessionStorage.getItem("clause"));
    // let othersList = JSON.parse(sessionStorage.getItem("others"));
    // let noticeList = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    // let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    // let about = JSON.parse(sessionStorage.getItem("about"));
    // if (this.is8V) {
    //   this.rateUrl = othersList[0].language[`url_${this.language}`];
    //   this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    //   this.tipUrl = about[0].language[`url_${this.language}`];
    //   this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    //   this.statementUrl = clauseList[2].language[`url_${this.language}`];
    //   this.helpUrl = about[1].language[`url_${this.language}`];
    // } else {
    //   this.privacyUrl = clauseList[1].url;
    //   this.statementUrl = clauseList[2].url;
    //   this.serviceUrl = clauseList[0].url;
    //   this.rateUrl = othersList[0].url;
    //   this.tipUrl = about[0].url;
    //   this.helpUrl = about[1].url;
    // }
    // if (othersList[0].language) {
    //   this.rateUrl = othersList[0].language[`url_${this.language}`];
    // } else {
    //   this.rateUrl = othersList[0].url;
    // }
    // if (othersList[1]) {
    //   this.hasAPI = true;
    //   this.apiUrl = othersList[1].url;
    // } else {
    // }
    // if (clauseList[0].language) {
    //   this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    // } else {
    //   this.serviceUrl = clauseList[0].url;
    // }
    // if (about[0].language) {
    //   this.tipUrl = about[0].language[`url_${this.language}`];
    // } else {
    //   this.tipUrl = about[0].url;
    // }
    // if (clauseList[1].language) {
    //   this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    // } else {
    //   this.privacyUrl = clauseList[1].url;
    // }
    // if (clauseList[2].language) {
    //   this.statementUrl = clauseList[2].language[`url_${this.language}`];
    // } else {
    //   this.statementUrl = clauseList[2].url;
    // }
    // if (about[1].language) {
    //   this.helpUrl = about[1].language[`url_${this.language}`];
    // } else {
    //   this.helpUrl = about[1].url;
    // }
    // this.tgUrl = qrcode.ori_url;
    // this.qrcodeTitle = qrcode.title;
  },
  methods: {
    translateTitle,
    tipsDownload() {
      this.$emit("tipsDownload");
    },
    openChat() {
      if (!this.userInfo) {
        this.$message(this.translateTitle("请先登录"));
        return;
      }
      this.$emit("chatOpen");
    },
    // onKycClick() {
    //   this.$router.push({
    //     path: "/kyc-notice"
    //   });
    // },
    // onAPIClick() {
    //   this.$emit("onApiClick");
    // },
    // onClickMore() {
    //   window.open(this.tipUrl);
    // }
  },
};
</script>
<style lang="scss">
.page-footer-bv {
  position: relative;
  padding: 0 0 40px;
  background-color: #112132;
  .split-line {
    margin: 0 auto;
    margin-bottom: 50px;
    width: 1200px;
    height: 1px;
    // background-color: #979797;
  }
  .first-content {
    display: flex;
    justify-content: center;
  }
  .second-content {
    width: 1000px;
    margin: 0px auto;
    padding: 20px 90px;
    border-top: 1px solid #fdac35;
    color: #fdac35;
    &.V2 {
      border-top: 1px solid #7e8392;
      color: #7e8392;
    }
    p {
      font-size: 14px;
      line-height: 2;
      margin-bottom: 10px;
      vertical-align: middle;
      img {
        width: 14px;
        height: 16px;
        vertical-align: middle;
      }
    }
  }
  // background-color: #17232c;
  color: #fff;
  text-align: center;
  user-select: none;

  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  .page-footer-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 1200px;
    // border-bottom: solid 0.5px #182c3c;
    .footer-icons {
      margin-top: 30px;
      .iconfont {
        width: 16px;
        height: 12px;
        color: #7e8392;
        margin-right: 16px;
        cursor: pointer;
        &:hover {
          color: #357de1;
          // width: 20px;
          // height: 20px;
          // border-radius: 50%;
          // color: #ffffff;
        }
      }
    }
    .logo-content {
      margin-left: 30px;
      margin-top: 10px;
      text-align: left;
      img {
        // width: 191px;
        // height: 74px;
        width: 180px;
        height: auto;
        margin-bottom: 12px;
      }
      .contract {
        font-size: 12px;
        line-height: 1.58;
        letter-spacing: 0.2px;
        color: #697786;
        margin-bottom: 6px;
      }
      .slogan-c {
        margin-top: -6px;
        margin-bottom: 6px;
        font-size: 12px;
        font-weight: bold;
        color: #b9bec3;
      }
      // .sub-title {
      //   margin-top: 10px;
      //   opacity: 0.71;
      //   font-size: 16px;
      //   font-weight: bold;
      //   color: #fff;
      // }
    }
    .tg-content {
      .qr_code_canvas {
        margin-bottom: 10px;
        canvas {
          border: 3px solid white;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
      li {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    .tco {
      margin-right: 40px;
      flex-shrink: 0;
      // width: 350px;
      display: flex;
      justify-content: space-between;
      .text-content {
        vertical-align: top;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 52px;
        li {
          font-size: 12px;
          line-height: 1.75;
          letter-spacing: 0.26px;
          color: #697786;
          margin-bottom: 17px;
          text-align: left;
          margin-bottom: 6.5px;
          cursor: pointer;
          &:hover {
            color: rgba(255, 255, 255, 0.7);
          }
        }
        .sub-title {
          margin-bottom: 13px;
          font-size: 14px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .split-h {
    position: absolute;
    margin-top: 20px;
    width: 1150px;
    height: 0.5px;
    background-color: #182c3c;
    left: 50%;
    transform: translateX(-50%); // 通用
  }
  .copy-right {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    span {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #697786;
    }
  }
}
</style>
